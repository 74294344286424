@import "./colors";

$primary: #246682;
$primary-dark: darken($primary, 5%);

$nav-link-hover-bg: darken($primary, 10%);


@import "../base/variables";

